import logop from '../../images/LOGOSM.PNG';
import {useLocation,Link} from 'react-router-dom';
import {useEffect, useState, Fragment} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {Alert, Button} from "@material-tailwind/react";
import toast, {Toaster} from 'react-hot-toast';

const DatosCliente = (props) => {
    const [show, setShow] = useState(true);
    const [usosCFDI, setUsosCFDI] = useState([]);
    const [regimenFiscales, setRegimenFiscales] = useState([]);
    const [datosTicket, setDatosTicket] = useState([]);
    const location = useLocation();
    //const notify = () => toast.success('Successfully toasted!');

    //console.log(location.state);
    // Hook para redireccionar
    const navigate = useNavigate();
    // validación y leer los datos    

    useEffect(() => {
        setDatosTicket(location.state);
        axios.get("http://facturaciongrupopampas.com/UsosCFDI/Listado")
            .then(function (response) {
                // handle success
                setUsosCFDI(response.data.List);
            }).catch(function (error) {
            // handle error
            console.log(error);
        }).then(function () {
            // always executed
        });

        axios.get("http://facturaciongrupopampas.com/RegimenFiscales/Listado")
            .then(function (response) {
                // handle success            
                setRegimenFiscales(response.data.List);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });

        /* fetch('http://facturaciongrupopampas.com/UsosCFDI/Listado', {
             headers: {
                 "Access-Control-Allow-Origin": "*"
             }
         })
             .then(response => response.json())
             .then((data) => {
                 setUsosCFDI(data.List);
             });*/

        /*fetch('http://facturaciongrupopampas.com/RegimenFiscales/Listado', {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(response => response.json())
            .then((data) => {
                setRegimenFiscales(data.List);
            });*/
    }, []);

    const formik = useFormik({
        initialValues: {
            razonSocial: '',
            rfc: '',
            codUsoCFDI: 0,
            idRegimen: 0,
            email: '',
            cp: '',

        },
        validationSchema: Yup.object({
            razonSocial: Yup.string()
                .min(1, 'La razón social es obligatorio')
                .required('La razón social es requerida'),
            rfc: Yup.string()
                .min(1, 'El rfc es obligatorio')
                .required('El rfc es requerida'),
            email: Yup.string()
                .min(1, 'Debes agregar un correo')
                .required('El correo es obligatorio'),
            cp: Yup.string()
                .min(1, 'Debes agregar un código postal')
                .required('El código postal es obligatorio')

        }),
        onSubmit: datos => {
            //console.log(datos);
            //console.log(location.state);

            axios.post('http://facturaciongrupopampas.com/TEST/save40', {
                codEmpresa: datosTicket.codEmpresa,
                total: datosTicket.total,
                fechaConsumo: datosTicket.fechaFactura,
                folio: datosTicket.numeroTicket,
                cantidadFactura: datosTicket.totalFactura,
                rfcClie: datos.rfc,
                razonSocialClie: datos.razonSocial,
                correo: datos.email,
                codUsoCFDI: datos.codUsoCFDI,
                cpCliente: datos.cp,
                idRegimen: datos.idRegimen
            })
                .then(res => {
                    
                    if(res.data.success){
                        toast.success('Factura enviada con exito!', {duration: 5000});
                        //window.location.reload();
                    }else{
                        toast.error(res.data.responseMessage.msgError, {duration: 5000});
                        //delay(5000);
                        //window.location.reload();
                    }
                    //console.log(res.data);
                });

            /* fetch('http://facturaciongrupopampas.com/TEST/save40', requestOptions)
                 .then(response => response.json())
                 .then((data) => { console.log(data); });*/

            /*   fetch('http://facturaciongrupopampas.com/TEST/save40', {
                   method: 'POST',
                   mode: 'cors',
                   body: JSON.stringify({
                     // Add parameters here
                     codEmpresa:100,
                     folio:2
                   }),
                   headers: {
                     'Content-type': 'application/json; charset=UTF-8',
                      "Access-Control-Allow-Origin": "*"
                   },
                 })
                    .then((response) => response.json())
                    .then((data) => {
                       console.log(data);
                       // Handle data
                    })
                    .catch((err) => {
                       console.log(err.message);
                    });*/


            /* const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                objDocumento: JSON.stringify({
                    // Add parameters here
                    codEmpresa:100
                  })
            };

            fetch('http://facturaciongrupopampas.com/TEST/save40',requestOptions)
                .then(response => response.json())
                .then((data) =>{
                console.log(data);
            } );*/

            //navigate('/DatosCliente',{state:datos});
            //<Link to="/DatosCliente/1">Producto 1</Link>
        }
    });
    return (
        <>
            <Toaster position="top-right"/>
            <div className="">
                <div className="mt-3 flex justify-center">
                    <img src={logop} className="w-1/2" alt=""/>
                </div>
                <h1>Datos del clientes</h1>
                <div className="flex justify-center mt-10">
                    <div className="w-full max-w-3xl">
                        <form
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2"
                                       htmlFor="numeroTicket">RFC del cliente:</label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="rfc"
                                    type="text"
                                    placeholder="RFC del cliente"
                                    value={formik.values.rfc}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.rfc && formik.errors.rfc ? (
                                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5"
                                     role="alert">
                                    <p className="font-bold">Hubo un error:</p>
                                    <p>{formik.errors.rfc} </p>
                                </div>
                            ) : null}
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2"
                                       htmlFor="razonSocial">Razón social:</label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="razonSocial"
                                    type="text"
                                    placeholder=""
                                    value={formik.values.razonSocial}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.razonSocial && formik.errors.razonSocial ? (
                                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5"
                                     role="alert">
                                    <p className="font-bold">Hubo un error:</p>
                                    <p>{formik.errors.razonSocial} </p>
                                </div>
                            ) : null
                            }
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2"
                                       htmlFor="email">Correo del cliente:</label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="text"
                                    placeholder=""
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.email && formik.errors.email ? (
                                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5"
                                     role="alert">
                                    <p className="font-bold">Hubo un error:</p>
                                    <p>{formik.errors.email} </p>
                                </div>
                            ) : null
                            }
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2"
                                       htmlFor="cp">Código postal del cliente:</label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="cp"
                                    type="text"
                                    placeholder=""
                                    value={formik.values.cp}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.cp && formik.errors.cp ? (
                                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5"
                                     role="alert">
                                    <p className="font-bold">Hubo un error:</p>
                                    <p>{formik.errors.cp} </p>
                                </div>
                            ) : null
                            }
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">Uso del
                                    CFDI</label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="codUsoCFDI"
                                    id="codUsoCFDI"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    {usosCFDI.map((usoCFDI, index) => (
                                        <option key={usoCFDI.codUsoCFDI} value={usoCFDI.codUsoCFDI}>
                                            {usoCFDI.descripcion}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">Regimen
                                    fiscal del cliente</label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="idRegimen"
                                    id="idRegimen"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    {regimenFiscales.map((regimenFiscal, index) => (
                                        <option key={regimenFiscal.idRegimen} value={regimenFiscal.idRegimen}>
                                            ({regimenFiscal.codigo}) {regimenFiscal.descripcion}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid px-4 py-3 bg-gray-50  sm:px-6">
                            <div className="col-start-1 col-end-3">
                                <Link to="../" 
                                    class="ml-100 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-blackhover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >Regresar
                                </Link>
                            </div>
                            <div className="col-end-13">
                                <input
                                    type="submit"
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-yellow-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    value="Enviar"
                                />
                            </div>
                            </div>
                            
                        </form>
                    </div>
                </div>

            </div>
        </>
    );
}
export default DatosCliente;